.contact-form {
    margin: 0 auto;
    padding: 1em;
    background: var(--background-secondary-color);
    border-radius: 5px;
    box-shadow: 0 0 10px var(--shadow-color);
}

.contact-form h2 {
    margin-bottom: 1em;
    font-size: 1.5em;
    color: var(--secondary-color);
}

.contact-form input, .contact-form textarea {
    background: var(--input-color);
    color: var(--primary-color);
}

.contact-form p {
    margin-bottom: 1.5em;
    color: var(--text-secondary-color);
}

.contact-form label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--secondary-color);
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 0.5em;
    margin-bottom: 1em;
    border: 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
}

.contact-form button {
    padding: 0.7em 1.5em;
    border: none;
    border-radius: 4px;
    background: var(--active-color);
    color: white;
    font-size: 1em;
    cursor: pointer;
}

.contact-form button:disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

.contact-form button:not(:disabled):hover {
    background: var(--active-secondary-color);
}

.is-required {
    color: red;
}