.App-header {
        font-family: "Kay Pho Du", serif;
        font-weight: 400;
        font-style: normal;
        margin-bottom: 5em;
}

.name {
        font-family: "Kay Pho Du", serif;
        text-align: center;
}

.App-header h1 {
        font-size: 4rem;
}

.header-links {
        font-size: 1.2em;
        display: flex;
        justify-content: center;
        gap: 2em;
        margin-bottom: 2em;
}

.profile-picture {
        width: 170px;
        height: 170px;
        margin: 0 auto;
        display: block;
        border: 3px solid var(--primary-color);
        border-radius: 50%;

}