body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.light-mode {
  background-color: #eeefef;
  color: black;
}

body {
  background-color: var(--background-color);
  color: var(--primary-color);
}
.light-mode {
  --primary-color: black;
  --secondary-color: #333;
  --background-color: #eeefef;
  --background-secondary-color: #faf9f9;
  --active-color: #3b3bf6;
    --disabled-color: #989696;
  --input-color: white;
  --shador-color: rgba(0, 0, 0, 0.1);
  --text-secondary-color: #666;
  --active-secondary-color: #5151fa;
  --border-color: hsl(185, 0%, 30%);
}
.dark-mode {
  --primary-color: white;
    --secondary-color: #ccc;
  --background-color: #36363a;
    --background-secondary-color: #2f2f33;
  --active-color: #4586F6;
  --disabled-color: #666;
    --input-color: #12121299;
    --shadow-color: rgba(0, 0, 0, 0.5);
    --text-secondary-color: #838080;
    --active-secondary-color: #5151fa;;
  --border-color: hsl(185, 0%, 70%);
}
