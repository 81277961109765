.xp-info {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-end;
    grid-template-areas:
        "title year"
        "company website";
}

.company {
    grid-area: company;
    margin-bottom: 0.5em;
}

.year {
    grid-area: year;
}

.website {
    grid-area: website;
    justify-self: end;
    align-self: center;
}

.mt-0 {
    margin-top: 0;
}

.company-logo {
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
    border-radius: 25%;
}

.ext-title {
    grid-area: title;
    margin-bottom: 0.5rem;
}

.company {
    font-size: 1.2rem;
}

.desc {
    margin-top: 1.3em;
}