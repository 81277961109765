.sidebar-buttons {
    position: fixed;
    top: 5%;
    right: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.btn:hover {
    cursor: pointer;
}
.darkmode-btn {
    height: 3rem;
    width: 3rem;
}

.language-btn {
    height: 2rem;
    width: 3rem;
    object-fit: cover;
}
@media screen and (max-width: 767px) {
    .sidebar-buttons {
        top: 2%;
        right: 2%;
    }
    .darkmode-btn {
        height: 1.5rem;
        width: 1.5rem;
    }
    .language-btn {
        height: 1rem;
        width: 1.5rem;
    }

}