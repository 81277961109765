.App {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-areas: "SidebarMenu Content .";
}
@media screen and (max-width: 767px) {
    .App {
        grid-template-columns: 10% 80% 10%;
        grid-template-areas: "SidebarMenu" "Content .";
    }

}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
}
h2 {
    font-size: 2rem;
}

p, li, label, select, b, a, span, button {
    font-family: 'Poppins', sans-serif;
}

html {
    scroll-behavior: smooth;
}

.SidebarMenu {
    grid-area: SidebarMenu;
}
.Content {
    grid-area: Content;
}


a:hover {
    opacity: 0.8;
}
a {
    text-decoration: none;
    color: unset;
}