.sidebar-menu ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1.2em;
}
.sidebar-menu li {
    padding: 0.5em;
    margin: 0.5em 0.5em 0.7em;
    cursor: pointer;
}

.sidebar-menu {
    position: fixed;
    margin-left: 1.5em;
    margin-top: 1.5em;
}
.sidebar-menu {
    scroll-behavior: smooth;
}
.active {
    color: var(--active-color);
    font-weight: bold;
    text-decoration: none;
    position: relative;
}

.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Adjust as needed */
    width: 0;
    height: 2px; /* Adjust as needed */
    background-color: var(--active-color);
    animation: underlineAnimation 0.5s forwards;
}

@keyframes underlineAnimation {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .sidebar-menu {
        margin-left: 0.5em;
        margin-top: 0.5em;
    }
    .sidebar-menu ul {
        font-size: 1em;
    }
    .sidebar-menu li {
        padding: 0.3em 0.3em 0.3em 0;
        margin: 0.3em 0.3em 0.5em 0;
    }
}