.sort-select {
    padding: 0.4rem;
    border: 1px solid ;
    border-radius: 5px;
    border-color: var(--border-color);
    background-color: var(--background-secondary-color);
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    color: var(--primary-color);
    cursor: pointer;
    transition: all 0.3s;
}