.project-card {
    border: 2px solid var(--border-color);
    border-radius: 20px;
    margin: 20px 0;
    padding: 10px;
    background: var(--background-secondary-color);
}

.project-image {
    max-width: 150px;
    max-height: 150px;
    border-radius: 10%;
}

.project-header {
    display: flex;
    justify-content: space-between;
}

.project-date {
    margin-right: 15px;
    margin-top: 15px;
}

.project-links {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.ml-small {
    margin-left: 0.5em;
}

.bold-light {
    font-weight: 600;
}