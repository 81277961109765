.ghLink {
    font-weight: 600;
}
.footer {
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    gap: 1em;
    justify-content: center;
}