.notification {
    margin-top: 10px;
    padding: 8px;
    border-radius: 5px;
    border: 2px solid;
    color: var(--primary-color);
    font-size: 0.9em;
    font-weight: 500;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.success {
    background-color: #4CAF50;
    border-color: rgba(76, 175, 80, 0.74);
}
.error {
    background-color: #f44336;
    border-color: rgba(244, 67, 54, 0.68);

}
.warning {
    background-color: #ff9800;
}

.x-button {
    position: absolute;
    top: 12px;
    left: 12px;
    cursor: pointer;
    font-size: 1.0em;
}

.progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.1);
}

.progress {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    animation: progress 10s linear;
}

@keyframes progress {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}